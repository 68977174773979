/*@import url('https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css');*/
@tailwind base;
input:invalid, textarea:invalid, select:invalid {
    box-shadow: none;
}
@tailwind components;

@tailwind utilities;

[x-cloak] {
    display: none;
}
.scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.scrollbar::-webkit-scrollbar-track {
    border-radius: 50vh;
    background: #f7f4ed;
}

.scrollbar::-webkit-scrollbar-thumb {
    background: #e0cbcb;
    border-radius: 50vh;
    border: 1px solid #f6f7ed;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
}
@layer base {
    .navMenuLink {
        @apply m-2 px-3 py-2 flex justify-start rounded-md gap-2 hover:bg-purple-600 hover:text-white;
    }
    .subNavMenuLink {
        @apply m-2 px-3 py-2 flex justify-start rounded-md gap-2 hover:bg-gray-200 hover:text-gray-700 dark:hover:bg-gray-600 dark:hover:text-gray-100;
    }
    .navActive {
        @apply bg-purple-600 text-white;
    }
    .navInactive {
        @apply text-gray-700 dark:text-gray-200;
    }
    .subNavActive {
        @apply bg-gray-200 text-gray-700 dark:bg-gray-600 dark:text-gray-100;
    }
    .subNavInactive {
        @apply text-gray-500 dark:text-gray-300;
    }
    .active-submenu {
        @apply text-gray-800 dark:text-white;
    }
    .submenu-link {
        @apply inline-flex items-center w-full font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-white;
    }
    .active {
        @apply text-purple-700 dark:text-purple-400;
    }
    .sidebar-link {
        @apply inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-purple-700 dark:hover:text-purple-400;
    }
    .sidebar-button {
        @apply inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200;
    }
}
